export const msalConfig = {
    auth: {
        clientId: '94626cdf-fffe-4435-b451-95402a7f4546', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/9df58381-d805-40c5-9ce9-b94ec70a870d', // Replace the placeholder with your tenant subdomain 
        // redirectUri: 'http://localhost:3000', // Points to window.location.origin. You must register this URI on Microsoft Entra admin center/App Registration.
        redirectUri: 'https://solaraha.renew.com',
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: ["user.read", "openid", "profile"],
};
