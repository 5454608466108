import React from "react";
import Page from "@jumbo/shared/Page";
import { Navigate } from "react-router-dom";
import Login from "app/pages/auth-pages/login/Login";
import Error404 from "app/pages/extra-pages/Error404";
import Dashboard from "app/pages/Dashboard/Dashboard";
import Users from "app/pages/Users";
import State from "app/pages/State";
import Site from "app/pages/Site";
import Checklist from "app/pages/Checklist";
import Equipment from "app/pages/Equipment";
import AuditPlan from "app/pages/AuditPlan";
import AuditorSummary from "app/pages/AuditorSummary";
import AuditorChecklist from "app/pages/AuditorSummary/AuditorChecklist";
import AuditeeSummary from "app/pages/AuditeeSummary";
import AuditeeChecklist from "app/pages/AuditeeSummary/AuditeeChecklist";
import ReviewerSummary from "app/pages/ReviewerSummary";
import AuditorReviewChecklist from "app/pages/ReviewerSummary/AuditorReviewChecklist";
import ReviewChecklist from "app/pages/ReviewerSummary/ReviewChecklist";
import Reports from "app/pages/Dashboard/DashboardTabs/ReportsTab/Reports";
import DChecklist from "app/pages/Dashboard/DashboardTabs/ReportsTab/DChecklist";
import PreviousYearAuditData from "app/pages/PreviousYearAuditData";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    {
        path: "/",
        // element: <Page component={Login} />
        element: <Login />
    },
    {
        path: "*",
        element: <Page component={Error404} layout={"solo-page"} />
    }
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Navigate to={"/dashboard"} />
    },
    {
        path: "/dashboard",
        element: <Page component={Dashboard} />
    },
    {
        path: "/admin/users",
        element: <Page component={Users} />
    },
    {
        path: "/admin/state",
        element: <Page component={State} />
    },
    {
        path: "/admin/site",
        element: <Page component={Site} />
    },
    {
        path: "/admin/equipment",
        element: <Page component={Equipment} />
    },
    {
        path: "/admin/checklist",
        element: <Page component={Checklist} />
    },
    {
        path: "/audit-plan",
        element: <Page component={AuditPlan} />
    },
    {
        path: '/auditor-summary',
        element: <Page component={AuditorSummary} />
    },
    {
        path: '/auditor-checklist',
        element: <Page component={AuditorChecklist} />
    },
    {
        path: '/auditee-summary',
        element: <Page component={AuditeeSummary} />
    },
    {
        path: '/auditee-checklist',
        element: <Page component={AuditeeChecklist} />
    },
    {
        path: '/reviewer-summary',
        element: <Page component={ReviewerSummary} />
    },
    {
        path: '/auditor-review-checklist',
        element: <Page component={AuditorReviewChecklist} />
    },
    {
        path: '/review-checklist',
        element: <Page component={ReviewChecklist} />
    },
    {
        path: '/dashboard-reports',
        element: <Page component={Reports} />
    },
    {
        path: '/dashboard-checklist',
        element: <Page component={DChecklist} />
    },
    {
        path: '/previous-year-audit-data',
        element: <Page component={PreviousYearAuditData} />
    },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/",
        // element: <Page component={Login} />
        element: <Login />
    },
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};