import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AuthMethods from 'app/services';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useNavigate } from 'react-router-dom';
import { isValidEmail } from '@jumbo/utils';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../../../src/config';
import { useSnackbar } from 'notistack';

const Login = () => {

    const { setAuthToken } = useJumboAuth();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [message, setMessage] = useState('');
    const { instance, accounts } = useMsal();
    const [accountDetails, setAccountDetails] = useState(null);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = async (e) => {
        if (e) {
            e.preventDefault();
        }
        await instance
            .loginPopup(loginRequest)
            .catch((e) => {
                console.log("Error logging in", e)
            })
            .then((response) => {
                setEmail(response?.account?.username);
                setAccountDetails(response);

                AuthMethods["auth"].loginAdfs(response?.account?.username, setMessage).then((data) => {
                    if (data.token) {
                        setAuthToken(data.token);
                        navigate("/dashboard");
                    } else {
                        setEmailError(
                            "Entered credentials are not present in our system, please contact administrator"
                        );
                        navigate("/");
                    }
                });
            });
    };

    const onLogin = (event) => {
        event.preventDefault();

        let isValid = true;

        if (!email) {
            setEmailError("This field is required");
            isValid = false;
        } else if (!isValidEmail(email)) {
            setEmailError("Enter a valid email address");
            isValid = false;
        }

        if (!password) {
            setPasswordError("This field is required");
            isValid = false;
        }

        if (isValid) {
            AuthMethods['auth'].login(email, password, setMessage).then(data => {
                if (data.token) {
                    setAuthToken(data.token);
                }
            })
        }
    };

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const onChangePassword = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };

    useEffect(() => {

        if (message !== '') {
            if (message === 'Success') {
                enqueueSnackbar('Log in successful', {
                    variant: 'success'
                });
                navigate("/dashboard");
            } else {
                enqueueSnackbar(message, {
                    variant: 'error'
                });
            }
            setMessage('');
        }
    }, [message]);

    return (

        <Div
            sx={{
                width: '100%',
                height: '100vh',
            }}
        >
            <Avatar
                alt="Logo" src={'/images/auth/solar.jpg'}
                sx={{
                    width: '100%',
                    height: '100%',
                }}
                variant="square"
            />
            <Div
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    inset: 0,
                    position: 'absolute',
                    backgroundColor: theme => alpha(theme.palette.common.black, .05),
                    padding: '10px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '18px',
                        background: '#FFF',
                        boxShadow: '1px 1px 10px 0px #DBD8F1',
                        borderRadius: '10px',
                        width: '100%',
                        maxWidth: '382px',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Avatar
                            alt="Logo" src={'/images/auth/ReNew.png'}
                            sx={{
                                width: '37%',
                                height: '100%',
                                mt: 1,
                                mb: 1,
                            }}
                            variant="square"
                        />
                        <Avatar
                            alt="Logo" src={'/images/auth/ReD.png'}
                            sx={{
                                width: '24%',
                                height: '100%',
                                mt: 1,
                                mb: 1,
                                pl: 1,
                            }}
                            variant="square"
                        />
                    </Box>

                    <Typography
                        sx={{
                            color: theme => theme.palette.success.dark,
                            fontSize: '19px',
                            fontWeight: 'bold',
                            padding: '10px 0',
                            textAlign: 'center',
                        }}
                    >
                        ASSET HEALTH ASSURANCE - SOLAR
                    </Typography>

                    <Div sx={{ width: '100%' }}>
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                        >
                            {/* <Div sx={{ mb: 1, mt: 3 }}>
                                <TextField
                                    id="outlined-basic"
                                    type="email"
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    onChange={onChangeEmail}
                                    fullWidth
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            onLogin(e);
                                        }
                                    }}
                                />
                                <FormHelperText sx={{
                                    color: theme => theme.palette.error.main,
                                    ml: 2,
                                    minHeight: '3vh',
                                }}
                                >
                                    {emailError}
                                </FormHelperText>
                            </Div>
                            <Div sx={{ mb: 1 }}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        value={password}
                                        onChange={onChangePassword}
                                        label="Password"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                onLogin(e);
                                            }
                                        }}
                                    />
                                    <FormHelperText sx={{
                                        color: theme => theme.palette.error.main,
                                        ml: 2,
                                        minHeight: '3vh',
                                    }}
                                    >
                                        {passwordError}
                                    </FormHelperText>
                                </FormControl>
                            </Div> */}
                            {/* <Typography
                                textAlign={"right"}
                                variant={"body1"}
                                sx={{mb: 1}}
                            >
                                <Link underline="none" href="#">Forgot your password?</Link>
                            </Typography> */}
                            {/* <Div sx={{ mb: 2 }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    icon=""
                                    onClick={onLogin}
                                >
                                    Login
                                </Button>
                            </Div> */}

                            {/* <Typography
                                sx={{ 
                                textAlign: 'center',
                                fontSize: '13px',
                                m: 1,
                            }}>
                                OR
                            </Typography> */}
                            <Div sx={{ mt: 2, mb: 2 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                fullWidth
                                sx={{ whiteSpace: 'nowrap' }}
                                onClick={(e) => handleLogin(e)}
                            >
                                Login via ADFS
                            </Button>
                            </Div>
                        </Box>
                    </Div>
                </Box>
            </Div>
        </Div>
    );
};

export default Login;